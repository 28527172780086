.CommonCardPageContainer {
    width: 320px;
    position: relative;
}

.Test {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 276px;
    bottom: -20px;
    left: 50%;
    margin-left: -138px;
}

.CommonCardPageContent {
    height: 100%;
    padding: 30px 20px;

    h1:first-of-type {
        color: #06426A;
    }

    h1 {
        color: #4EA6FF;
    }

    hr {
        color: #CCCCCC;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    span {
        color: #295FA1
    }
}

button {
    border: none;
    background: none;
}
