.Footer {
    a {
        opacity: 0.7;
        color: #FFFFFF;
        font-family: Inter;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
    }
}