.CompletedTitle {
    color: #FBF88A;
    font-family: Bungee;
    font-size: 27px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    width: 213px;
}

.YBYLLogoContainer {
    height: 162px;
    width: 120.51px;
    margin-bottom: 30px;
    margin-top: 20px;
    img {
        height: 100%;
        width: 100%;
    }
}


.CompletedCopy {
    height: 100%;
    p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: left;
        line-height: 18px;
    }
    span {
        color: #295FA1
    }
}