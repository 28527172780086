.AnsweredComponent {
    position: relative;
    margin-top: auto;
    margin-bottom: 0;
    width: 100%;
    display: flex;
    padding: 3px;

}

.BtnLabel {
    flex: 1 0 auto;
    flex-wrap: wrap;
    width: 100%;
    word-wrap: break-word;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 12px 15px 10px;
    text-shadow: 0 0 0 rgba(45, 131, 220, 0);
    line-height: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: inherit;
}

.BtnLabelCorrect {
    background-color: #52B43D;
}

.BtnLabelGrey {
    background-color: #7E7E7E;
}

.BtnLabelWrong {
    background-color: #AE2129;
}

.BtnLabelMissed {
    background-color: #C1BD24;
}

.BtnIcon {
    flex: 0 0 auto;
    font-size: 16px;
    padding: 0 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: inherit;
}

.BtnIconGrey {
    background-color: #A4A4A4;
}

.BtnIconCorrect {
    background-color: #54C93B;
}

.BtnIconWrong {
    background-color: #CF2029;
}

.BtnIconMissed {
    background-color: #DAD52A;
}

@media (min-width: 480px) {
    .BtnLabel {
        font-size: 1rem;
    }
  }
  @media (min-width: 480px) {
    .btn-component.xl .btn-icon,
    .btn-component.xl .BtnLabel {
        padding: 15px 20px;
    }
  }
  @media (min-width: 720px) {
    .BtnLabel {
        font-size: 0.85rem;
    }
  }