.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.quad-1 {
  background: radial-gradient(circle at right, #034875 0%, #050E40 50%);
  height: 50%;
  width: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.quad-2 {
  background: radial-gradient(circle at left, #034875 0%, #050E40 50%);
  height: 50%;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.quad-3 {
  background: radial-gradient(circle at left, #034875 0%, #050E40 50%);
  height: 50%;
  width: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.quad-4 {
  background: radial-gradient(circle at right, #034875 0%, #050E40 50%);
  height: 50%;
  width: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.common-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  min-height: 600px;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: -60px;
}

.common-card-content {
  height: 100%;
  padding: 30px 25px;
}