* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #050E40;
}

h1 {
  font-size: 28px;
  font-weight: 800;
  letter-spacing: 0;
  padding: 0px;
  margin: 0px;
  height: 28px;
}

p {
  color: #4C4C4C;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-component {
  position: relative;
  margin-top: auto;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  padding: 3px;
  cursor: pointer;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.btn-component:after {
  position: absolute;
  content: '';
  box-shadow: 0 0 15px 0 #2d83dc, 0 0 15px 0 #2d83dc;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.btn-component .btn-label {
  flex: 1 0 auto;
  flex-wrap: wrap;
  width: 100%;
  word-wrap: break-word;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #0e6cae;
  text-transform: uppercase;
  padding: 12px 15px 10px;
  text-shadow: 0 0 0 rgba(45, 131, 220, 0);
  line-height: 1;
}

.btn-component .btn-icon,
.btn-component .btn-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: inherit;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.btn-component .btn-icon {
  flex: 0 0 auto;
  font-size: 16px;
  padding: 0 10px;
  background-color: #2d83dc;
}

.btn-component .btn-inline {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}



.btn-component.panels {
  border: none;
}

.btn-component.panels,
.btn-component.panels:after {
  box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
}

.btn-component.panels:after {
  opacity: 0;
}


.btn-selected.panels {
  border-color: #4FA6FF;
  background-color: #feb;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
}

.btn-selected.panels .btn-label {
  color: #fff;
  background-color: #4FA6FF;
  text-shadow: 0 0 10px #6AB4FF;
}


.btn-selected.panels .btn-icon {
  color: #fff;
  background-color: #6AB4FF;
}


.btn-selected.panels .btn-inline {
  border: 2px solid #6AB4FF;
}

.btn-component.reverse {
  flex-direction: row-reverse;
}

.btn-component.compact {
  flex: 0 0 auto;
  width: auto;
}

.btn-component.flex-start {
  align-self: flex-start;
}

.btn-component.flex-center {
  align-self: center;
}

.btn-component.flex-end {
  align-self: flex-end;
}

.btn-component.absolute {
  position: absolute;
}

.btn-component.bottom-centered {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.btn-component.sm:after {
  box-shadow: 0 0 5px 0 #2d83dc, 0 0 5px 0 #2d83dc;
}

.btn-component.sm .btn-label {
  font-size: 0.7rem;
  padding: 10px 15px;
}

.btn-component.sm .btn-icon {
  padding: 0 10px;
  font-size: 0.7rem;
  width: auto;
}

.btn-component.xs:after {
  box-shadow: 0 0 0 0 #2d83dc, 0 0 0 0 #2d83dc;
}

.btn-component.xs .btn-icon,
.btn-component.xs .btn-label {
  font-size: 0.7rem;
  padding: 5px 10px;
}

.btn-component.xs .btn-icon {
  width: auto;
}

.btn-component.xl .btn-label {
  font-size: 1.1rem;
  padding: 12px 15px 10px;
}

.btn-component.xl .btn-icon {
  padding: 10px;
  font-size: 1.1rem;
  width: auto;
}

.btn-component.hide {
  display: none;
}

.btn-component.show,
.btn-icons {
  display: flex;
}

.btn-icons {
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 20px;
  right: 20px;
  font-size: 1.4rem;
  color: #5eaeff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 1001;
}

li.li6:not(:last-child) { 
  margin-bottom: 12px;  
}

@media (min-width: 480px) {
  .btn-component .btn-label {
    font-size: 1rem;
  }
}

@media (min-width: 480px) {

  .btn-component.xl .btn-icon,
  .btn-component.xl .btn-label {
    padding: 15px 20px;
  }
}

@media (min-width: 720px) {
  .btn-component .btn-label {
    font-size: 0.85rem;
  }
}

@media (hover: hover) and (pointer: fine) {
  .btn-component:hover {
    border-color: #5eaeff;
    text-decoration: none;
  }

  .btn-component:hover:after {
    opacity: 1;
  }

  .btn-component:hover .btn-label {
    color: #fff;
    background-color: #2d83dc;
    text-shadow: 0 0 10px #2d83dc;
  }

  .btn-component:hover .btn-icon {
    color: #fff;
    background-color: #5eaeff;
  }

  .btn-component:hover .btn-inline {
    border: 2px solid #5eaeff;
  }

  .btn-component.panels:hover {
    border-color: #4FA6FF;
    background-color: #feb;
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
  }

  .btn-component.panels:hover .btn-label {
    color: #fff;
    background-color: #4FA6FF;
    text-shadow: 0 0 10px #4FA6FF;
  }

  .btn-component.panels:hover .btn-icon {
    color: #fff;
    background-color: #6AB4FF;
  }

  .btn-component.panels:hover .btn-inline {
    border: 2px solid #6AB4FF;
  }

  .btn-icons:hover {
    color: #fff;
  }
}

.footer-container {
  min-height: 60px;
  bottom: 0;
  padding: 20px 10px 20px 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.common-card-page-content {
  height: 100%;

  p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  a {
    color: #295FA1;
    font-size: 14px;
    text-decoration: none;
  }
}

.common-form-container {
  margin-top: 15px;
  flex-direction: column;
  display: flex;
  align-items: center;

  label {
    color: #4C4C4C;
    font-family: Inter;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
  }
}

.common-input-container {
  margin-top: 5px;

  input {
    font-size: 16px;
    border: 1px solid #565656;
    font-family: 'Inter', sans-serif;
  }

  small {
    margin-top: 5px;
  }
}

.common-input {
  height: 36px;
  background-color: #FFFFFF;
  width: 273px;
  border-radius: 4px;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

button#purge.btn-component.panels:hover {
  border-color: #AE2129;
  background-color: #feb;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
}

button#purge.btn-component {
  margin-top: 20px;

  &:hover {
    border-color: #CF2029;
    text-decoration: none;

    .btn-label {
      color: #fff;
      background-color: #CF2029;
      text-shadow: 0 0 10px #CF2029;
    }

    .btn-inline {
      border: 2px solid #CF2029;
    }
  }

  .btn-label {
    background-color: #AE2129;
    text-shadow: 0 0 10px #AE2129;
  }
}