.HeroContainer {
    max-height: 250px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 25px;
}

.LogoImageContainer {
    max-width: 287px;
    max-height: 250px;
}

.LogoImage {
    height: 100%;
    width: 100%;
}

.CityScape {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

@media only screen and (max-width: 375px) {  
    .HeroContainer {
        max-height: 200px;
        min-height: 150px;
    }
    .LogoImageContainer {
        width: 230px;
        height: 200px;
    }
}